import cubejs from '@cubejs-client/core';
import cubeConfig from './cubeConfig.json';  // Import the JSON file

const CUBEJS_API_URL = process.env.REACT_APP_CUBE_API_HOST;
const CUBEJS_API_TOKEN = 'your-cubejs-api-token';

const cubejsApi = cubejs(CUBEJS_API_TOKEN, {
  apiUrl: `${CUBEJS_API_URL}/cubejs-api/v1`
});

/**
 * Generic Cube.js Query function based on a key from JSON config
 * @param {String} functionalityName - The key in the JSON config
 * @returns {Promise} - The response from the Cube.js query
 */
export function fetchCubeData(functionalityName, filters) {
  try {
    const queryConfig = cubeConfig[functionalityName];  // Fetch the query details from the JSON

    if (!queryConfig) {
      throw new Error(`No query configuration found for functionality: ${functionalityName}`);
    }

    const query = {
      measures: queryConfig.measures || [],
      dimensions: queryConfig.dimensions || [],
      timeDimensions: queryConfig.timeDimension
        ? [{
            dimension: queryConfig.timeDimension,
            granularity: queryConfig.granularity || null
          }]
        : [],
      filters: queryConfig.filters || filters || [],
      order: queryConfig.order || []
    };

    return cubejsApi.load(query);
  } catch (error) {
    console.error('Error executing Cube.js query:', error);
    throw error;
  }
}
