// middleware/countyClickMiddleware.js
import { loadCubeData } from '../actions/fetchActions';

const countyClickMiddleware = (store) => (next) => (action) => {
    if (action.type === '@@kepler.gl/LAYER_CLICK') {
        console.log('Intercepted LAYER_CLICK action:', action); // Log the action for debugging
        const featureData = action.info?.object;

        if (featureData) {
            const countyName = featureData.properties?.county || featureData.properties?.name; // Adjust property path based on structure
            if (countyName) {
                const filters = [
                    {
                        member: 'ncei_individual_yearly.cz_name',
                        operator: 'equals',
                        values: [countyName.toUpperCase()]
                    }
                ];
                store.dispatch(loadCubeData(filters));
            } else {
                console.error('County information not found in feature data');
            }
        }
    }
    return next(action);
};

export default countyClickMiddleware;
