import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { taskMiddleware } from 'react-palm/tasks';
import keplerGlReducer from 'kepler.gl/reducers';
import appReducer from './reducers/mapReducer';
import countyClickMiddleware from './services/countyClickMiddleware.js';

// Combine reducers
const reducers = combineReducers({
    keplerGl: keplerGlReducer, // Kepler.gl reducer
    app: appReducer
});

// Create store with thunk, taskMiddleware, and countyClickMiddleware
const store = createStore(
    reducers,
    {},
    applyMiddleware(thunk, taskMiddleware, countyClickMiddleware)
);

export default store;
