import { handleActions } from 'redux-actions';
import { ActionTypes, INIT } from 'kepler.gl/actions';
import { SET_CUBE_DATA, SET_NCEI_DATA } from '../actions/fetchActions';

const initialState = {
    loaded: false,
    selectedFeature: null,
    selectedCounty: null,
    barData: [],
    areaData: [],
    nceiData: [] // Store NCEI dataset here
};

const appReducer = handleActions(
    {
        [INIT]: (state) => ({
            ...state,
            loaded: true
        }),

        [ActionTypes.LAYER_CLICK]: (state, { payload }) => {
            const index = payload?.info?.index;
            if (index !== undefined && state.nceiData.length > 0) {
                const clickedFeature = state.nceiData[index];

                // Replace with the actual property key for county name
                const countyName =
                    clickedFeature?.['ncei.name'] || clickedFeature?.county || clickedFeature?.name;

                console.log('County clicked:', countyName);

                if (countyName) {
                    return {
                        ...state,
                        selectedFeature: clickedFeature,
                        selectedCounty: countyName
                    };
                }
            }
            console.warn('County name not found in clicked feature.');
            return state;
        },

        [ActionTypes.SET_FILTER]: (state, { payload }) => {
            return payload?.prop === 'value' && payload.value
                ? { ...state, selectedCounty: payload.value }
                : state;
        },

        [SET_CUBE_DATA]: (state, { payload }) => ({
            ...state,
            barData: payload.barData,
            areaData: payload.areaData,
            lineData: payload.lineData,
            pieData: payload.pieData,
            plotData: payload.plotData,
            barPlotData: payload.barPlotData
        }),

        [SET_NCEI_DATA]: (state, { payload }) => ({
            ...state,
            nceiData: payload
        })
    },
    initialState
);

export default appReducer;
