import { addDataToMap } from 'kepler.gl/actions';
import { processGeojson, processRowObject } from 'kepler.gl/processors';
import wellknown from 'wellknown';
import { fetchCubeData } from '../services/cubeQuery';
import shmpConfig from './shmp-config.json';

export const SET_CUBE_DATA = 'SET_CUBE_DATA';
export const CLEAR_CUBE_DATA = 'CLEAR_CUBE_DATA';
export const SET_NCEI_DATA = 'SET_NCEI_DATA';

const fieldLabelMap = {
    'ncei.name': 'County',
    'ncei.count_event': 'Count of Event Type',
    'ncei.damage_crops': 'Total Crops Damage $',
    'ncei.damage_property': 'Total Property Damage $'
};

// Utility function to fetch data from Cube.js with error handling
const fetchDataFromCube = async (query, filters = []) => {
    try {
        return await fetchCubeData(query, filters);
    } catch (error) {
        console.error(`Error fetching data from Cube.js for query ${query}:`, error);
        throw error;
    }
};

// Action to fetch SHMP data by county and dispatch to Kepler.gl
export const fetchNceiData = () => async (dispatch) => {
    try {
        const [resultSet, countiesResult] = await Promise.all([
            fetchDataFromCube('SHMP_DATA_BY_COUNTY'),
            fetchDataFromCube('COUNTIES')
        ]);

        const counties = countiesResult.rawData();
        const processedRows = resultSet.rawData().map((row) => {
            const county = counties.find((c) => c['counties.name'] === row['ncei.name']);
            if (county) {
                row['geom'] = county['counties.geom'];
                row['counties.longitude'] = county['counties.longitude'];
                row['counties.latitude'] = county['counties.latitude'];
            }
            return row;
        });

        const processedData = processRowObject(processedRows);

        // Update field display names based on fieldLabelMap
        processedData.fields.forEach((field) => {
            if (fieldLabelMap[field.name]) {
                field.displayName = fieldLabelMap[field.name];
            }
        });

        console.log('Processed Data for Kepler.gl:', processedData);

        // Dispatch the raw data to store it in Redux
        dispatch({ type: SET_NCEI_DATA, payload: processedRows });

        // Dispatch data to Kepler.gl for rendering
        dispatch(
            addDataToMap({
                datasets: {
                    info: { id: 'dataset_ncei', label: 'SHMP' },
                    data: processedData
                },
                options: { centerMap: true, readOnly: false },
                config: shmpConfig.polygon
            })
        );
    } catch (error) {
        console.error('Error processing NCEI data:', error);
    }
};

// Enhanced action to load Cube.js data based on dynamic filters
export const loadCubeData = (filters) => async (dispatch) => {
    try {
        const [data1, data2, data3, data4, data5, data6] = await Promise.all([
            fetchDataFromCube('SHMP_DATA_BY_YEAR_COUNT', filters),
            fetchDataFromCube('SHMP_DATA_BY_YEAR', filters),
            fetchDataFromCube('TOP_DAMAGE_YEARS', filters),
            fetchDataFromCube('COUNT_OF_FLOOD_CAUSE', filters),
            fetchDataFromCube('DAMAGE_PROPERTY_CROPS_BY_YEAR', filters),
            fetchDataFromCube('DAMAGE_TOTAL_BY_EVENT', filters)
        ]);

        dispatch({
            type: SET_CUBE_DATA,
            payload: {
                barData: data1.rawData(),
                areaData: data2.rawData(),
                lineData: data3.rawData(),
                pieData: data4.rawData(),
                plotData: data5.rawData(),
                barPlotData: data6.rawData()
            }
        });
    } catch (error) {
        console.error('Error fetching Cube data with filters:', error);
    }
};

// Action to clear Cube data from the Redux store
export const clearCubeData = () => ({
    type: CLEAR_CUBE_DATA
});
